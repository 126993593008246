<template>
  <div>
    <FilterQuote @getQuote="searchQuote" @resetFilters="resetFilters" />
    <TableQuote @getQuoteTable="getQuote" @resetFilters="resetFilters" />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterQuote from "@/components/components-fit/gestione-quote/FiltriQuote.vue";
import TableQuote from "@/components/components-fit/gestione-quote/TableQuote.vue";

export default defineComponent({
  name: "gestione-quote",
  components: {
    FilterQuote,
    TableQuote,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Causali report");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const idFamigliaQuote = computed(() => store.getters.idFamigliaQuote);
    const idSottofamigliaQuote = computed(
      () => store.getters.idSottofamigliaQuote
    );
    const tipoQuote = computed(() => store.getters.tipoQuote);
    const descrizioneQuote = computed(() => store.getters.descrizioneQuote);
    const idReportPrimoLivelloQuote = computed(
      () => store.getters.idReportPrimoLivelloQuote
    );
    const idReportSecondoLivelloQuote = computed(
      () => store.getters.idReportSecondoLivelloQuote
    );
    const causaleQuote = computed(() => store.getters.causaleQuote);
    const nonAssociateQuote = computed(() => store.getters.nonAssociateQuote);

    const rowsToSkip = computed(() => store.getters.rowsToSkipQuote);
    const fetchRows = computed(() => store.getters.fetchRowsQuote);
    const sortColumn = computed(() => store.getters.sortColumnQuote);
    const sortOrder = computed(() => store.getters.sortOrderQuote);

    const getQuote = () => {
      const localKeys = {
        id_famiglia: idFamigliaQuote.value,
        id_tipologia: idSottofamigliaQuote.value,
        id_tipo: tipoQuote.value,
        id_primo_livello: idReportPrimoLivelloQuote.value,
        id_secondo_livello: idReportSecondoLivelloQuote.value,
        descrizione: descrizioneQuote.value,
        causale: causaleQuote.value,
        non_associate: nonAssociateQuote.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.GESTIONE_QUOTE_LIST,
        itemName: "gestione_quote_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersQuote");
      getQuote();
    };
    getQuote();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedgestione_quote_list")
    );

    const searchQuote = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipQuote");
      getQuote();
    };

    return {
      getQuote,
      resetFilters,
      searchQuote,
    };
  },
});
</script>
