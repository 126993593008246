<template>
  <div
    class="modal fade"
    tabindex="-10"
    id="modal_edit_quota"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modifica associazioni causale</h5>
          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-secondary"
            style="border: 0; !important"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="
              id_primo_livello = null;
              id_secondo_livello = null;
            "
          >
            <i class="bi bi-x-lg"> </i>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body text-start">
          <div class="card-body p-6">
            <div class="ps-5 pe-5" v-if="quota_view_list">
              <div class="row pb-4"></div>
              <p class="fw-bold">{{ quota_view_list.descrizione }}:</p>
              <div class="row pb-4">
                <div class="col-sm-5 filter">
                  <div class="dp__input_wrap text-gray-600">
                    <SelectInput
                      placeholder="Report primo livello"
                      :options="primo_livello_list"
                      name="id_primo_livello"
                      :value="id_primo_livello"
                      @changeSelect="setIdReportPrimoLivelloQuote($event)"
                    />
                    <span
                      class="help-message"
                      style="color: red"
                      v-if="errorPrimoLivello"
                    >
                      Obbligatorio
                    </span>
                  </div>
                </div>
                <div class="col-sm-5 filter">
                  <div class="dp__input_wrap text-gray-600">
                    <SelectInput
                      placeholder="Report secondo livello"
                      :options="secondo_livello_list"
                      name="id_secondo_livello"
                      :value="id_secondo_livello"
                      :disabled="!id_primo_livello"
                      @changeSelect="setIdReportSecondoLivelloQuote($event)"
                    />
                  </div>
                </div>
                <div class="col-sm-2 filter">
                  <span
                    type="button"
                    class="badge bg-secondary rounded blueFit fs-6"
                    @click="aggiungiAssociazione()"
                  >
                    Aggiungi
                  </span>
                </div>
              </div>
              <hr />

              <div>
                <div class="mx-auto my-auto text-center" v-if="loadingDetail">
                  <div class="spinner-border text-gray-600" role="status"></div>
                  <span class="text-gray-600 ps-3 fs-2">Loading</span>
                </div>
                <div
                  v-else-if="
                    quota_view_list.reportistica == undefined ||
                    quota_view_list.reportistica == null ||
                    quota_view_list.reportistica == ''
                  "
                  class="m-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
                >
                  <span class="text-gray-700 ps-3 fs-4">
                    Nessuna causale associata
                  </span>
                </div>
                <div v-else class="table-responsive card shadow">
                  <table class="table table-striped gy-3 gs-7 align-middle">
                    <thead>
                      <tr
                        class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                      >
                        <th scope="col">Report primo livello</th>
                        <th scope="col">Report secondo livello</th>
                        <th scope="col" class="w-5"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(report, index) in quota_view_list.reportistica"
                        :key="index"
                      >
                        <tr>
                          <td>{{ report.primo_livello }}</td>
                          <td>{{ report.secondo_livello }}</td>
                          <td>
                            <button
                              class="badge bg-light-danger rounded fs-5"
                              @click="deleteAssociazione(report.id)"
                            >
                              <i class="bi bi-trash text-danger fs-5"></i>
                            </button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            type="button"
            class="me-3 badge bg-light-danger rounded text-danger fs-6"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="
              id_primo_livello = null;
              id_secondo_livello = null;
            "
          >
            Chiudi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, getCurrentInstance, ref, watch } from "vue";
import { useStore } from "vuex";

import isEnabled from "@/composables/isEnabled.js";
import { alertFailed } from "@/composables/swAlert";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import {
  viewQuota,
  delCausaleQuota,
  addCausaleQuota,
} from "@/requests/quoteRequests.js";

export default defineComponent({
  name: "view-documenti-partecipante",
  components: { Loading, SelectInput },
  emits: ["getQuoteList"],
  props: {
    selectedQuota: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const isLoading = ref(false);

    const primo_livello_list = computed(() =>
      store.getters.getStateFromName("primo_livello_list")
    );
    const secondo_livello_list = ref([]);
    const keys = ref("rpcl");
    if (!primo_livello_list.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const errorPrimoLivello = ref(false);

    const id_primo_livello = ref(null);
    const setIdReportPrimoLivelloQuote = (event) => {
      errorPrimoLivello.value = false;
      id_primo_livello.value = event;
      secondo_livello_list.value.splice(0, secondo_livello_list.value.length);
      primo_livello_list.value.forEach((element) => {
        if (element.id_pl === parseInt(id_primo_livello.value)) {
          if (element.slarr) {
            element.slarr.forEach((secondo) => {
              secondo_livello_list.value.push(secondo);
            });
          }
        }
      });
      id_secondo_livello.value = null;
    };

    const id_secondo_livello = ref(null);
    const setIdReportSecondoLivelloQuote = (event) => {
      id_secondo_livello.value = event;
    };

    const aggiungiAssociazione = () => {
      if (!id_primo_livello.value) {
        errorPrimoLivello.value = true;
      } else {
        errorPrimoLivello.value = false;
        isLoading.value = true;
        addCausaleQuota(
          props.selectedQuota,
          id_primo_livello.value,
          id_secondo_livello.value
        ).then((res) => {
          isLoading.value = false;
          if (res.status == 200) {
            id_secondo_livello.value = null;
            id_primo_livello.value = null;
            getQuotaView(props.selectedQuota);
            emit("getQuoteList");
          } else {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
          }
        });
      }
    };

    const deleteAssociazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await delCausaleQuota(id, props.selectedQuota).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              getQuotaView(props.selectedQuota);
              emit("getQuoteList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
          });
        }
      });
    };

    const loadingDetail = ref(false);
    const quota_view_list = ref(null);

    const getQuotaView = (id_quota) => {
      loadingDetail.value = true;
      viewQuota(id_quota).then((res) => {
        loadingDetail.value = false;
        if (res.status == 200) {
          quota_view_list.value = res.data.dettaglio_configurazione_quote[0];
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi",
            "dettaglio_classifica"
          );
        }
      });
    };
    watch(
      () => props.selectedQuota,
      () => {
        getQuotaView(props.selectedQuota);
      }
    );

    return {
      isEnabled,
      isLoading,
      getQuotaView,
      loadingDetail,
      quota_view_list,
      primo_livello_list,
      secondo_livello_list,
      id_primo_livello,
      id_secondo_livello,
      setIdReportPrimoLivelloQuote,
      setIdReportSecondoLivelloQuote,
      delCausaleQuota,
      deleteAssociazione,
      aggiungiAssociazione,
      errorPrimoLivello,
    };
  },
});
</script>

<style scoped>
.modal-content {
  width: 140%;
}

.modal-body {
  padding: 0px;
}

.modal-dialog {
  margin-top: 3rem;
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 52%;
    margin: 9rem;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog {
    max-width: 70%;
    margin: 3px;
  }
}

.image-input [data-kt-image-input-action] {
  cursor: pointer;
  position: absolute;
  transform: none;
}

.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: solid 1px;
  border-color: #d6d8dd;
}

.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 3px;
  height: 28px;
  width: 28px;
}

.image-input [data-kt-image-input-action="change"] {
  left: 0%;
  top: 0%;
}
</style>
