<template>
  <div>
    <div class="row mt-5">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />

    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="gestione_quote_list.length === 0 && loaded"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="gestione_quote_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsQuote"
        @current-change="setCurrentPageQuote"
      >
        <template v-slot:cell-famiglia="{ row: data }"
          ><div class="">
            {{ data.famiglia }}
          </div>
        </template>
        <template v-slot:cell-tipologia="{ row: data }"
          ><div class="">
            {{ data.tipologia }}
          </div>
        </template>
        <template v-slot:cell-tipo="{ row: data }"
          ><div class="">
            {{ data.tipo }}
          </div>
        </template>
        <template v-slot:cell-causale="{ row: data }"
          ><div class="">
            {{ data.causale }}
          </div>
        </template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long">
            {{ data.descrizione }}
          </div>
        </template>
        <template v-slot:cell-reportistica="{ row: data }"
          ><div
            class="tab-long d-flex my-1"
            v-for="(report, index) in data.reportistica"
            :key="index"
          >
            {{ report.primo_livello }}
            <i
              v-if="report.secondo_livello"
              class="bi bi-arrow-right align-self-center mx-2"
            ></i>
            {{ report.secondo_livello }}
          </div>
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div class="btn-group">
            <button
              class="btn btn-sm dropdown p-1"
              data-bs-toggle="modal"
              data-bs-target="#modal_edit_quota"
              @click="selectedQuota = data.id_quota"
            >
              <i class="bi bi-pen text-dark fs-5"></i>
            </button>
          </div>
        </template>
      </Datatable>
    </div>
    <EditQuota
      :selectedQuota="selectedQuota"
      @getQuoteList="$emit('getQuoteTable')"
    />
  </div>
</template>

<script>
import Datatable from "../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";

import EditQuota from "./EditQuota.vue";
import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "TableQuote",
  components: { Datatable, EditQuota },
  emits: ["getQuoteTable", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Famiglia",
        key: "famiglia",
      },
      {
        name: "Sottofamiglia",
        key: "tipologia",
      },
      {
        name: "Tipo",
        key: "tipo",
      },
      {
        name: "Causale",
        key: "causale",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Reportistica",
        key: "reportistica",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const store = useStore();

    const setFetchRowsQuote = (e) => {
      store.commit("setFetchRowsQuote", e);
      emit("getQuoteTable");
    };
    const setCurrentPageQuote = (page) => {
      store.commit("setCurrentPageQuote", page);
      emit("getQuoteTable");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnQuote", columnName);
      store.commit("setSortOrderQuote", order);
      emit("getQuoteTable");
    };

    const selectedQuota = ref(null);

    return {
      tableHeader,
      setFetchRowsQuote,
      isEnabled,
      setCurrentPageQuote,
      setSortOrderColumn,

      gestione_quote_list: computed(() =>
        store.getters.getStateFromName("resultsgestione_quote_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedgestione_quote_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordgestione_quote_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusgestione_quote_list")
      ),
      currentPage: computed(() => store.getters.currentPageQuote),
      rowsToSkip: computed(() => store.getters.rowsToSkipQuote),
      fetchRows: computed(() => store.getters.fetchRowsQuote),
      sortColumn: computed(() => store.getters.sortColumnQuote),
      sortOrder: computed(() => store.getters.sortOrderQuote),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      selectedQuota,
    };
  },
};
</script>

<style scoped></style>
