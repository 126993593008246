<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getQuote')">
        <div class="row pb-4">
          <div class="col-sm-3 filter">
            <div class="dp__input_wrap text-gray-600">
              <SelectInput
                placeholder="Famiglia"
                :options="quote_famiglie"
                name="id_famiglia"
                :value="idFamigliaQuote"
                @changeSelect="setIdFamigliaQuote($event)"
              />
            </div>
          </div>
          <div class="col-sm-3 filter">
            <div class="dp__input_wrap text-gray-600">
              <SelectInput
                placeholder="Sottofamiglia"
                :options="quote_tipologie"
                name="id_tipologia"
                :value="idSottofamigliaQuote"
                @changeSelect="setIdSottofamigliaQuote($event)"
              />
            </div>
          </div>
          <div class="col-sm-3 filter">
            <div class="dp__input_wrap text-gray-600">
              <SelectInput
                placeholder="Tipo"
                :options="quote_tipo_causale"
                name="id_tipo"
                :value="tipoQuote"
                @changeSelect="setTipoQuote($event)"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              placeholder="Descrizione"
              aria-label=""
              :value="descrizioneQuote"
              @input="setDescrizioneQuote"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3">
            <SelectInput
              placeholder="Report primo livello"
              :options="primo_livello_list"
              name="id_primo_livello"
              :value="idReportPrimoLivelloQuote"
              @changeSelect="setIdReportPrimoLivelloQuote($event)"
            />
          </div>
          <div class="col-sm-3">
            <SelectInput
              placeholder="Report secondo livello"
              :options="secondo_livello_list"
              name="id_secondo_livello"
              :value="idReportSecondoLivelloQuote"
              @changeSelect="setIdReportSecondoLivelloQuote($event)"
              :disabled="!idReportPrimoLivelloQuote"
            />
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control"
              aria-label=""
              placeholder="Causale"
              :value="causaleQuote"
              @input="setCausaleQuote"
            />
          </div>
          <div class="col align-self-center text-start">
            <input
              class="form-check-input"
              type="checkbox"
              :value="nonAssociateQuote"
              :checked="nonAssociateQuote == true"
              @click="
                non_associate = !non_associate;
                setNonAssociateQuote(non_associate);
              "
            />
            <label class="fw-bold ps-1 text-gray-800">
              Causali non associate
            </label>
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getQuote')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="
              $emit('resetFilters');
              non_associate = false;
            "
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-gestione-quote",
  emits: ["resetFilters", "getQuote"],
  components: {
    SelectInput,
  },

  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const quote_famiglie = computed(() =>
      store.getters.getStateFromName("quote_famiglie")
    );
    const quote_tipologie = computed(() =>
      store.getters.getStateFromName("quote_tipologie")
    );
    const quote_tipo_causale = computed(() =>
      store.getters.getStateFromName("quote_tipo_causale")
    );
    /* const primo_livello = computed(() =>
      store.getters.getStateFromName("primo_livello")
    );
    const secondo_livello = computed(() =>
      store.getters.getStateFromName("secondo_livello")
    ); rpl|rps*/
    const primo_livello_list = computed(() =>
      store.getters.getStateFromName("primo_livello_list")
    );
    const secondo_livello_list = ref([]);
    const keys = ref("qf|qt|qtc|rpcl");
    if (
      !quote_famiglie.value ||
      !quote_tipologie.value ||
      !quote_tipo_causale.value ||
      !primo_livello_list.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const idFamigliaQuote = computed(() => store.getters.idFamigliaQuote);
    const setIdFamigliaQuote = (event) => {
      store.commit("setIdFamigliaQuote", event);
    };
    const idSottofamigliaQuote = computed(
      () => store.getters.idSottofamigliaQuote
    );
    const setIdSottofamigliaQuote = (event) => {
      store.commit("setIdSottofamigliaQuote", event);
    };
    const tipoQuote = computed(() => store.getters.tipoQuote);
    const setTipoQuote = (event) => {
      store.commit("setTipoQuote", event);
    };
    const descrizioneQuote = computed(() => store.getters.descrizioneQuote);
    const setDescrizioneQuote = (event) => {
      store.commit("setDescrizioneQuote", event.target.value);
    };
    const idReportPrimoLivelloQuote = computed(
      () => store.getters.idReportPrimoLivelloQuote
    );
    const setIdReportPrimoLivelloQuote = (event) => {
      store.commit("setIdReportPrimoLivelloQuote", event);
      secondo_livello_list.value.splice(0, secondo_livello_list.value.length);
      primo_livello_list.value.forEach((element) => {
        if (element.id_pl === parseInt(idReportPrimoLivelloQuote.value)) {
          if (element.slarr) {
            element.slarr.forEach((secondo) => {
              secondo_livello_list.value.push(secondo);
            });
          }
        }
      });
      setIdReportSecondoLivelloQuote(null);
    };
    const idReportSecondoLivelloQuote = computed(
      () => store.getters.idReportSecondoLivelloQuote
    );
    const setIdReportSecondoLivelloQuote = (event) => {
      store.commit("setIdReportSecondoLivelloQuote", event);
    };
    const causaleQuote = computed(() => store.getters.causaleQuote);
    const setCausaleQuote = (event) => {
      store.commit("setCausaleQuote", event.target.value);
    };
    const nonAssociateQuote = computed(() => store.getters.nonAssociateQuote);
    const setNonAssociateQuote = (event) => {
      store.commit("setNonAssociateQuote", event);
    };

    return {
      quote_famiglie,
      quote_tipologie,
      quote_tipo_causale,
      primo_livello_list,
      secondo_livello_list,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedgestione_quote_list")
      ),
      idFamigliaQuote,
      idSottofamigliaQuote,
      tipoQuote,
      descrizioneQuote,
      idReportPrimoLivelloQuote,
      idReportSecondoLivelloQuote,
      causaleQuote,
      nonAssociateQuote,
      setIdFamigliaQuote,
      setIdSottofamigliaQuote,
      setTipoQuote,
      setDescrizioneQuote,
      setIdReportPrimoLivelloQuote,
      setIdReportSecondoLivelloQuote,
      setCausaleQuote,
      setNonAssociateQuote,
    };
  },
});
</script>

<style></style>
