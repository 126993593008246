import services from "../axios/dbManag";

export const viewQuota = (id_quota) => {
  return services.dbManag
    .post("/reportistica/configurazione/causali/view", {
      id_quota,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const delCausaleQuota = (id, id_quota) => {
  return services.dbManag
    .post("/reportistica/configurazione/causali/del", {
      id,
      id_quota,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addCausaleQuota = (
  id_quota,
  id_primo_livello,
  id_secondo_livello
) => {
  return services.dbManag
    .post("/reportistica/configurazione/causali/add", {
      id_quota,
      id_primo_livello,
      id_secondo_livello,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
